import { defineStore } from 'pinia';
import useFlashTokenStore from '~/store/flashTokenStore';
import { jwtDecode } from 'jwt-decode';

const useUserStore = defineStore('userStore', {
    state: () => ({
        userId: null as string | null,
        userType: null as string | null,
        property_uuid: null as string | null,
    }),
    getters: {
        getUserId: (state) => state.userId,
        getUserType: (state) => state.userType,
        getPropertyUUID: (state) => state.property_uuid,
    },
    actions: {
        setUserId() {
            const flashTokenStore = useFlashTokenStore();
            const decodedToken: any = jwtDecode(flashTokenStore.getAccessToken);
            this.userId = decodedToken['custom:flash_user_id'];
        },

        setUserType(userType: string) {
            this.userType = userType;
        },

        setPropertyUuid(propertyUuid: string) {
            this.property_uuid = propertyUuid;
        },

        async setUserDetailsFromEsSite() {
            await this.$nuxt.$axios.$get(`/api/v1/users/${this.userId}`).then((res: any) => {
                this.setUserType(res.account_type);
                this.setPropertyUuid(res.property_uuid);
            });
        },
        async markAllThreadAsRead() {
            await this.$nuxt.$axios.$patch(`/api/v1/users/${this.userId}/mark-all-as-read`);
        },
    },
});

export default useUserStore;
