
import { defineComponent } from '@nuxtjs/composition-api';
import { analyticsMixin } from '@/mixins/analytics';
import { getEsNavBarInstallerAccountContent, getEsNavBarInstallerGlobalContent } from '~/index';
import {
    EsNavBar,
    EsFooter,
    getEsFooterContent,
    getEsNavBarAccountContent,
    getEsNavBarGlobalContent,
} from '@energysage/es-nav';
import useUserStore from '~/store/userStore';

export default defineComponent({
    name: 'DefaultLayout',
    components: {
        EsNavBar,
        EsFooter,
    },
    mixins: [analyticsMixin],
    computed: {
        userIsInstaller(): boolean {
            const userStore = useUserStore();
            return userStore.getUserType === 'supplier';
        },
        footerContent() {
            const footerContent = getEsFooterContent();

            // update home link to root-relative url
            footerContent.home.link = process.env.ES_DOMAIN!;

            return footerContent;
        },
        navAccountContent() {
            return this.userIsInstaller ? getEsNavBarInstallerAccountContent() : getEsNavBarAccountContent();
        },
        navGlobalContent() {
            const navGlobalContent = this.userIsInstaller
                ? getEsNavBarInstallerGlobalContent()
                : getEsNavBarGlobalContent();

            // update home link to root-relative url
            navGlobalContent.home.link = process.env.ES_DOMAIN!;
            return navGlobalContent;
        },
    },
});
